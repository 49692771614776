/**
 * Form component that uses ./input components.
 *
 * This component sends down 2 methods through childContext - `onChange` and `register`.
 * These methods allow inputs to register to the form state and to send up changes
 * and validations to the top level form state, which allows the form to monitor
 * it's own validation state.
 */

import { PureComponent } from 'react'

import styled from '@emotion/styled'

import { Button } from '@stories/buttons/button'

import FormSubscriber from './form-subscriber'

import { bool, func, node, string } from 'prop-types'

const Form = styled.form`
  width: 100%;
`

// TODO: Remove legacy button component

export default class FormComponent extends PureComponent {
  constructor() {
    super()
    // Formerly in componentWillMount()
    this.subscriber = new FormSubscriber()
  }

  state = {
    valid: false,
    inputs: {}
  }

  static propTypes = {
    // Whether to include a simple self-managed primary-styled button. If this is
    // set to false, include buttons (./button.tsx) in the children
    // that will use the form's isFormValid and onSubmit
    includeButton: bool.isRequired,
    // Whether the form has submitted and is waiting for a return
    loading: bool,
    // Action run when form is valid
    onSubmit: func.isRequired,
    getIsValid: func,
    // Text on submit button e.g. 'Signup', 'Login'
    submitText: string,
    noValidate: bool,
    children: node,
    submitId: string
  }

  static defaultProps = {
    includeButton: true,
    onSubmit: (e) => {
      e.preventDefault()
    },
    noValidate: false,
    getIsValid: () => {}
  }

  static childContextTypes = {
    isFormValid: func,
    onChange: func,
    onSubmit: func,
    register: func,
    deregister: func,
    subscribe: func,
    unsubscribe: func,
    getInputStates: func
  }

  isFormValid = () => Object.values(this.state.inputs).every((input) => input.valid)

  onSubmit = (e) => {
    e.preventDefault()

    if (this.props.noValidate || this.isFormValid()) {
      this.props.onSubmit(this.state.inputs)
    }
  }

  register = (name, value = '', valid = false) => {
    this.setValuesForInputs(name, value, valid)
  }

  deregister = (name) => {
    const newInputs = this.state.inputs
    delete newInputs[name]
    this.setState({ inputs: newInputs })
  }

  subscribe = (key, fn) => {
    this.subscriber.subscribe(key, fn)
  }

  unsubscribe = (key) => {
    this.subscriber.unsubscribe(key)
  }

  getInputStates = () => {
    return this.state.inputs
  }

  onChange = (name, value, valid) => {
    this.setValuesForInputs(name, value, valid)
    this.subscriber.updateInputs()
    this.props.getIsValid(this.isFormValid())
  }

  setValuesForInputs = (name, value, valid) => {
    const inputsState = this.state.inputs
    inputsState[name] = { value, valid }

    this.setState({ inputs: inputsState })
  }

  getChildContext = () => {
    return {
      isFormValid: this.isFormValid,
      onChange: this.onChange,
      onSubmit: this.onSubmit,
      register: this.register,
      deregister: this.deregister,
      subscribe: this.subscribe,
      unsubscribe: this.unsubscribe,
      getInputStates: this.getInputStates
    }
  }

  render() {
    let { children, includeButton, loading, submitId, submitText, onSubmit, ...formProps } = this.props
    onSubmit = this.onSubmit

    return (
      <Form action="" onSubmit={onSubmit} {...formProps}>
        {children}
        {includeButton && (
          <Button type="submit" loading={loading} id={submitId}>
            {submitText}
          </Button>
        )}
      </Form>
    )
  }
}
