/**
 * This is used to get around issues created by using React context methods.
 * Based on https://medium.com/@mweststrate/how-to-safely-use-react-context-b7e343eff076#.b3r9anw1i
 */

export default class FormSubscriber {
  constructor() {
    this.inputs = {}
  }

  updateInputs() {
    Object.values(this.inputs).forEach((input) => input())
  }

  subscribe(key, fn) {
    this.inputs[key] = fn
  }

  unsubscribe(key) {
    delete this.inputs[key]
  }
}
